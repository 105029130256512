import React from "react";
import User from "./User.js";

class Users extends React.Component {
    render() {
        return (
            <div className="all_users">
                {this.props.msgs.map((el) => (
                    <User msg={el} key={el.id} onBlock={this.props.onBlock}/>
                ))}
            </div>
        )
    }
}

export default Users