import React from "react";

class ChatImg extends React.Component {
    render() {
        return (
            <img src={this.props.url} className="chat-img" alt=""></img>
        )
    }
}

export default ChatImg