import React from "react";
import { IoSend } from "react-icons/io5";

class InputMsg extends React.Component {
    constructor(props) {
        super(props)
        this.Input = React.createRef()
    }
    componentDidMount() {
        this.Input.current.focus()
        console.log(this.Input)
        this.Input.current.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                const msg = e.target.value
                e.target.value = ''

                this.props.onSend(this.props.user,
                    msg
                )
     
            }})
        
    }
    
    render() {
        return (
            <div className="chat-input">
                <textarea id='inp' ref={this.Input} placeholder="Сообщение" onChange={(e) => {this.setState({msg: e.target.value})}}/><IoSend className="chat-send" onClick={() => {   
                    this.Input.value = 'Отправка...'
                    this.props.onSend(this.props.user, this.state.msg)
                    this.Input.value = ''

                }}/>
            </div>
        )
    }

    

    
}

export default InputMsg