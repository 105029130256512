import React from "react";

class ChatText extends React.Component {
    render() {
        return (
            <p className="chat-text">
                {this.props.text}
            </p>
        )
    }
}

export default ChatText