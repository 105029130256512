import React from "react";
import Options from "./Options.js";
class Header extends React.Component {
    render () {
        return (
            <div className="header">
                <p className="header-left"><b>Входящие</b><b className="count">{this.props.notCallCount}</b></p>
                <Options className='header-rigt' onChange={this.props.onChange} accs = {this.props.accs}/>
            </div>
        )
    }
}
export default Header