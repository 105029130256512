import React from "react";
import Description from "./Description.js";
import { PiClosedCaptioningFill } from "react-icons/pi";
import { BiBlock } from "react-icons/bi";
import { Link } from "react-router-dom";
import CIrcle from "./Circle.js";
class User extends React.Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
            desc: false
        }
        this.onCLickDesc = this.onCLickDesc.bind(this)
    }

    render() {
        const msg = this.props.msg
        return (
            <div className="user">
                <div className="top"><p className="name"><b>{msg.name}</b><PiClosedCaptioningFill className="desc" onClick={() => {this.onCLickDesc()}}/> <BiBlock className="block" onClick={() => this.props.onBlock(msg)}/></p> <p className="ac_name"><i>{msg.ac_name}</i></p></div>
                <Link className="link" to={{ pathname: `/chat/${msg.user_id}/${msg.chat_id}`}}><div className="bottom" ><p className="text">{msg.msg.slice(0, 60)}{msg.msg.length > 50 && '...'}</p>{msg.call === 0 && <CIrcle />}</div></Link>
                {this.state.desc && <Description msg={msg} />}
            </div>
        )

    }

    onCLickDesc() {
        this.setState({desc: !this.state.desc})
    }

    

}

export default User