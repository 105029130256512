import { useParams } from "react-router-dom";
import Chat from "./Chat.js";

const ChatHook = () => {
    const params = useParams()
    const { user_id, chat_id } = params
    return (
        <Chat user_id={user_id} chat_id={chat_id}/>
    )
}

export default ChatHook