import { useNavigate } from 'react-router-dom';
import { IoCaretBackCircle } from "react-icons/io5";

const Back = () => {
    const history = useNavigate();

  const handleBackClick = () => {
    history(-1);
  };

  return (
    <IoCaretBackCircle onClick={handleBackClick} className='chat-back'/>
  )
}

export default Back