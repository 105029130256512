import React from "react";

import Header from "./Header.js";
import Users from "./Users.js";
import axios from "axios";

const tg = window.Telegram.WebApp
var user_id = tg.initDataUnsafe?.user?.id

class Msgs extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notCallCount: 0,
            messages: [],
            accs: [],
            load: true
        }
        document.title = 'Messages'
        axios.get(
            `https://tegaibro.ru:5001/api/messages/${user_id}/0`).then(
                (res) => {
                    this.setState({messages: res.data.messages, load: false, notCallCount: res.data.notCallCount});
                }
              )
        axios.get(
            'https://tegaibro.ru:5001/api/accounts'
        ).then((res) => {
            this.setState({accs: res.data.accs})
        })
        
        this.onClickBlock = this.onClickBlock.bind(this)
        this.onChangeAcc = this.onChangeAcc.bind(this)
        
    }
    render() {
        if (this.state.messages.length > 0) {
            return (
                <div className="container">
                    <Header notCallCount={this.state.notCallCount} onChange={this.onChangeAcc} accs = {this.state.accs}/>
                    <Users onBlock={this.onClickBlock} msgs={this.state.messages}/>
                </div>
            )
        } else {
            if (this.state.load) {
                return (
                    <div>
                        <center><h1>Loading...</h1></center>
                    </div>
                )
            } else {
            return (
                <div>
                    <center><h1>NO ACCES</h1></center>
                </div>
            )
        }
        }
    }

    onChangeAcc(el) {
 
        axios.get(
                `https://tegaibro.ru:5001/api/messages/${user_id}/${el.target.value}`).then(
                    (res) => {
                        this.setState({messages: res.data.messages, load: false, notCallCount: res.data.notCallCount});
                    })
        
    }
    onClickBlock(msg) {
        axios.post('https://tegaibro.ru:5001/api/user/block',
            {
                'user_id': msg.user_id,
                'chat_id': msg.chat_id,
            },
            {
                headers: {
                    "Content-Type": 'application/json'
                }
            }
        ).then((res) => {
            if (res.data.status === `User as blocked`){ 
                this.setState({messages: this.state.messages.filter((el) => el.id !== msg.id)})
                alert(`User ${msg.name} as blocked`)
            } else {
                alert(res.data.status)
            }
        })    
    }
}

export default Msgs