import React from "react";
import ChatImg from "./ChatImg.js";
import ChatText from "./ChatText.js";
class ChatMsg extends React.Component {
    render() {
        const msg = this.props.msg
        const date = msg.date.split('.')[0]
        return (
            <div className={msg.class}>
                <p className="name">
                    {msg.name}
                </p>
               
                                
                {msg.content.type === 'text' ? <ChatText text={msg.content.content.text}/> : <ChatImg url={msg.content.content?.image} />}
                {msg.content.type === 'voice' && <ChatText text="*Голосовое сообщение*"/>}
                {msg.content.type === "location" && <ChatText text="*Геолокация*" />}
                <p className="date">
                    {date}
                </p>
                
            </div>
        )
    }
}

export default ChatMsg