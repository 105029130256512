import React from "react";
import Back from "./Back.js";
import { BiBlock } from "react-icons/bi";

class ChatHeader extends React.Component {
    render() {
        return (
            <div className="chat-head">
                <Back /><BiBlock className="chat-block" onClick={() => this.props.onBlock(this.props.user)}/><p>{this.props.username}</p>
            </div>
        )
    }
}

export default ChatHeader