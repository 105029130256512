import React from "react";

class Options extends React.Component {
    render() {
        return (
            <div className="options">
                <select onChange={this.props.onChange}>
                    <option value='0'>Все</option>
                    {this.props.accs.map((el) => (<option key={el.app} value={el.app}>{el.name}</option>))}
                </select>
            </div>
        )
    }
}
export default Options