import React from "react";


class Description extends React.Component {
    render() {
        return (
            <div className="description">
                <p className="loc"><i>Локация: {this.props.msg.loc}</i></p>
                <p className="vac"><i>{this.props.msg.vac.length !== 0 ? "Вакансия: " + this.props.msg.vac : ''}</i></p>
            </div>
        )
    }
}
export default Description