import React from "react";
import axios from "axios";
import ChatMsg from "./ChatMsg.js";
import ChatHeader from "./ChatHeader.js";
import InputMsg from "./InputMsg.js";



class Chat extends React.Component {

    constructor(props) {
        super(props)
       
        
        axios.get(`https://tegaibro.ru:5001/api/chat/${Number(this.props.user_id)}/${this.props.chat_id}`).then(async (el) => {
            this.setState({messages: el.data.msgs.messages, app: el.data.app, user_id: el.data.user_id, chat_id: el.data.chat_id, username: el.data.username, load: true})
            document.title = el.data.username
            
        })
        this.state = {
            app: 0,
            user_id: 0,
            chat_id: '',
            username: 'Loading...',
            messages: [],
            load: false
        }
        this.onClickSend = this.onClickSend.bind(this)

    }

    render() {
        if (this.state.messages.length > 0) {

            return (
                <div className="container">
                    <ChatHeader username={this.state.username} onBlock={this.onClickBlock} user={{user_id: this.state.user_id, chat_id: this.state.chat_id}}/>
                    <div className="chat">
                        {this.state.messages.map((msg) => (<ChatMsg msg={msg} key={this.state.messages.indexOf(msg)}/>))}
                    </div>
                    <InputMsg user={{user_id: this.state.user_id, chat_id: this.state.chat_id}} onSend={this.onClickSend}/>
                </div>
                
            )
        } else {
            return (
                <center><h1>Loading...</h1></center>
            )
        }
    }

    onClickBlock(user) {
        axios.post('https://tegaibro.ru:5001/api/user/block',
            {
                'user_id': user.user_id,
                'chat_id': user.chat_id,
            },
            {
                headers: {
                    "Content-Type": 'application/json'
                }
            }
        ).then((res) => {
            if (res.data.status === `User as blocked`){ 
                alert(`User as blocked`)
                window.location.href = 'https://tegaibro.ru/messages'
            } else {
                alert('Не удалось заблокировать пользователя')
            }
        })
    }

    onClickSend(user, msg) {
        const date = new Date()
        
        axios.post('https://tegaibro.ru:5001/api/user/send_message', 
            {
                user_id: user.user_id,
                chat_id: user.chat_id,
                msg: msg
            },
            {
                headers: {
                    "Content-Type": 'application/json'
                }
            }
        ).then(
            (res) => {
                this.setState({messages: [...this.state.messages, {
                    class: 'my-msg',
                    date: date.toISOString().replace('T', ' '),
                    name: res.data.name,
                    content: {class: 'chat-text',
                              type: 'text',
                              content: {text: msg}
                        
                    }
                }]})
                setTimeout(() => {
                    window.scroll(1000000, 100000)
                }, 1000)
                
            }
        ).catch(() => {
            alert('Не удалось отправить сообщение, попробуйте еще раз')
        })
    }
}

export default Chat